import React, { useState, useEffect, useContext } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import styled from "styled-components";
import { useToast } from "./Toast";
import { AuthContext } from "../context/AuthProvider";
import { ItemsContext } from "../context/ItemsContext";
import { BackButton, EditButton, DeleteButton, SaveButton, CancelButton } from "./Buttons";

import { Swiper, SwiperSlide } from "swiper/react";
// Note: Import modules from "swiper/modules" in Swiper v9+
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const DetailContainer = styled.div`
  padding: 20px 40px;
  font-family: "Arial", sans-serif;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  gap: 40px;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  max-width: 400px;
  text-align: center;
`;

const ItemImage = styled.img`
  width: 100%;
  max-width: 350px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const TextContainer = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  h1 {
    font-size: 24px;
    color: #333;
  }

  p {
    font-size: 16px;
    color: #555;
    margin: 5px 0;
  }

  p strong {
    font-weight: bold;
    color: #333;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
`;

const InputField = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 100%;
`;
const SelectField = styled.select`
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 100%;
`;

const Chip = styled.div`
  display: inline-block;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  color: #333;
  position: relative;
  cursor: default;
`;

const ChipClose = styled.span`
  position: absolute;
  top: -6px;
  right: -6px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 12px;
  cursor: pointer;
`;

const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const VisitStoreButton = styled.a`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: #0056b3;
  }
`;

const ItemDetail = () => {
  const location = useLocation();
  const [canGoBack, setCanGoBack] = useState(false);
  const navigate = useNavigate();
  const { item: initialItem } = location.state || {};
  const { item_purchase_id } = useParams();
  const [item, setItem] = useState(initialItem);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { items, setItems } = useContext(ItemsContext);
  const { userkey } = useContext(AuthContext);
  const { showToast } = useToast();

  const [isEditing, setIsEditing] = useState(false);
  const [itemName, setItemName] = useState(item ? item.name : "");
  const [itemBrand, setItemBrand] = useState(item ? item.brand : "");
  // Ensure we always have an array for image URLs.
  const [itemImageUrls, setItemImageUrls] = useState(
    item ? item.image_urls || [] : []
  );
  const [newImageUrl, setNewImageUrl] = useState("");
  const [itemStoreUrl, setItemStoreUrl] = useState(item ? item.store_url : "");
  const [itemColor, setItemColor] = useState(item ? item.color : "");
  const [itemType, setItemType] = useState(item ? item.item_type : "");
  const [itemSubcategories, setItemSubcategories] = useState(
    item && item.item_subcategory ? item.item_subcategory.split(",") : []
  );
  const [newSubcategory, setNewSubcategory] = useState("");

  const [itemFormalityTypes, setItemFormalityType] = useState(
    item && item.formality_type ? item.formality_type.split(":") : []
  );

  // Define possible options for the item type.
  const itemTypeOptions = ["top", "bottom", "dress", "shoes", "accessories"];

  useEffect(() => {
    const fromRoute = location.state?.from;
    setCanGoBack(fromRoute === "/closet");
  }, [location.state]);

  // Fallback API call to fetch item details if not provided via location.state.
  useEffect(() => {
    if (!initialItem && userkey && item_purchase_id) {
      const fetchItem = async () => {
        try {
          setLoading(true);
          const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/user/${userkey}/item/${item_purchase_id}`
          );
          const data = await response.json();
          if (response.ok) {
            setItem(data);
          } else {
            throw new Error("Failed to fetch item.");
          }
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };

      fetchItem();
    }
  }, [initialItem, userkey, item_purchase_id]);

  useEffect(() => {
    if (item) {
      setItemImageUrls(item.image_urls || []);
    }
  }, [item]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!item) {
    return <div>Item not found</div>;
  }

  const handleSave = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/item_purchases/${item.purchase_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer your_token",
          },
          body: JSON.stringify({
            name: itemName,
            brand: itemBrand,
            color: itemColor,
            item_type: itemType || "top",
            image_urls: itemImageUrls,
            store_url: itemStoreUrl,
            item_subcategory: itemSubcategories.join(","),
          }),
        }
      );

      if (response.ok) {
        const updatedItem = {
          ...item,
          name: itemName,
          brand: itemBrand,
          color: itemColor,
          item_type: itemType,
          image_urls: itemImageUrls,
          store_url: itemStoreUrl,
          item_subcategory: itemSubcategories.join(","),
        };

        setItem(updatedItem);
        const updatedItems = items.map((i) =>
          i.purchase_id === item.purchase_id ? updatedItem : i
        );
        setItems(updatedItems);
        showToast(`Successfully updated ${itemName}.`);
        setIsEditing(false);
      } else {
        showToast("Failed to update the item.", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      showToast("An error occurred while updating the item.", "error");
    }
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleRemoveSubcategory = (subcategoryToRemove) => {
    setItemSubcategories(
      itemSubcategories.filter((sub) => sub !== subcategoryToRemove)
    );
  };

  const handleAddSubcategory = (e) => {
    if (e.key === "Enter" && newSubcategory.trim() !== "") {
      setItemSubcategories([...itemSubcategories, newSubcategory.trim()]);
      setNewSubcategory("");
    }
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/item_purchases/${item.purchase_id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer your_token",
          },
        }
      );
      if (response.ok) {
        const updatedItems = items.filter((i) => i.item_id !== item.item_id);
        setItems(updatedItems);
        showToast(`Successfully deleted ${item.item_name} from your closet.`);
        navigate("/closet");
      } else {
        showToast("Failed to delete the item.", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      showToast("An error occurred while deleting the item.", "error");
    }
  };

  return (
    <DetailContainer>
      {canGoBack && <BackButton onClick={() => navigate(-1)}>Back</BackButton>}
      <ContentWrapper>
        <ImageContainer>
          {isEditing ? (
            <>
              {itemImageUrls.map((url, index) => (
                <div key={index} style={{ marginBottom: "10px" }}>
                  <InputField
                    value={url}
                    onChange={(e) => {
                      const updatedUrls = [...itemImageUrls];
                      updatedUrls[index] = e.target.value;
                      setItemImageUrls(updatedUrls);
                    }}
                    placeholder={`Image URL ${index + 1}`}
                  />
                  <button
                    type="button"
                    onClick={() => {
                      const updatedUrls = itemImageUrls.filter(
                        (_, i) => i !== index
                      );
                      setItemImageUrls(updatedUrls);
                    }}
                  >
                    Remove
                  </button>
                </div>
              ))}
              <InputField
                value={newImageUrl}
                onChange={(e) => setNewImageUrl(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && newImageUrl.trim() !== "") {
                    setItemImageUrls([...itemImageUrls, newImageUrl.trim()]);
                    setNewImageUrl("");
                  }
                }}
                placeholder="Add new image URL and press Enter"
              />
            </>
          ) : (
            <>
              {item.image_urls && item.image_urls.length > 0 ? (
                <Swiper
                  modules={[Navigation, Pagination]}
                  spaceBetween={10}
                  slidesPerView={1}
                  navigation
                  pagination={{ clickable: true }}
                >
                  {item.image_urls.map((url, index) => (
                    <SwiperSlide key={index}>
                      <ItemImage
                        src={encodeURI(url)}
                        alt={`${item.name} - Image ${index + 1}`}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <p>No images available</p>
              )}
            </>
          )}
        </ImageContainer>
        <TextContainer>
          {isEditing ? (
            <>
              <label>
                Name:
                <InputField
                  value={itemName}
                  onChange={(e) => setItemName(e.target.value)}
                  placeholder="Item Name"
                />
              </label>
              <label>
                Brand:
                <InputField
                  value={itemBrand}
                  onChange={(e) => setItemBrand(e.target.value)}
                  placeholder="Item Brand"
                />
              </label>
              <label>
                Color:
                <InputField
                  value={itemColor}
                  onChange={(e) => setItemColor(e.target.value)}
                  placeholder="Item Color"
                />
              </label>
              <label>
                Store site:
                <InputField
                  value={itemStoreUrl}
                  onChange={(e) => setItemStoreUrl(e.target.value)}
                  placeholder="Store URL"
                />
              </label>
              <label>
                Item type:
                <SelectField
                  value={itemType}
                  onChange={(e) => setItemType(e.target.value)}
                >
                  {itemTypeOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </SelectField>
              </label>
              <ChipContainer>
                {itemSubcategories.map((subcategory) => (
                  <Chip key={subcategory}>
                    {subcategory}
                    <ChipClose
                      onClick={() => handleRemoveSubcategory(subcategory)}
                    >
                      x
                    </ChipClose>
                  </Chip>
                ))}
                <InputField
                  value={newSubcategory}
                  onChange={(e) => setNewSubcategory(e.target.value)}
                  onKeyDown={handleAddSubcategory}
                  placeholder="Add Subcategory and press Enter"
                />
              </ChipContainer>
              <SaveButton onClick={handleSave}>Save Changes</SaveButton>
              <CancelButton onClick={handleEditToggle}>Cancel</CancelButton>
            </>
          ) : (
            <>
              <h1>{item.name}</h1>
              {item.status && (
                <p>
                  <strong>Status:</strong> {item.status}
                </p>
              )}
              {item.brand && (
                <p>
                  <strong>Brand:</strong> {item.brand}
                </p>
              )}
              {item.retailer && (
                <p>
                  <strong>Retailer:</strong> {item.retailer}
                </p>
              )}
              <p>
                <strong>Category:</strong> {item.item_type}
              </p>
              {item.color && (
                <p>
                  <strong>Color:</strong> {item.color}
                </p>
              )}
              {item.size && (
                <p>
                  <strong>Size:</strong> {item.size}
                </p>
              )}
              {item.notes && (
                <p>
                  <strong>Notes:</strong> {item.notes}
                </p>
              )}
              <ChipContainer>
                {itemSubcategories.map((subcategory) => (
                  <Chip key={subcategory}>{subcategory}</Chip>
                ))}

                {itemFormalityTypes.map((formality_type) => (
                  <Chip key={formality_type}>{formality_type}</Chip>
                ))}
              </ChipContainer>
              <ButtonGroup>
                <EditButton onClick={handleEditToggle}>Edit Item</EditButton>
                <DeleteButton onClick={handleDelete}>
                  Delete from Closet
                </DeleteButton>
                <VisitStoreButton
                  href={item.store_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View store link
                </VisitStoreButton>
              </ButtonGroup>
            </>
          )}
        </TextContainer>
      </ContentWrapper>
    </DetailContainer>
  );
};

export default ItemDetail;
