import { StytchProvider } from "@stytch/react";
import { StytchUIClient } from "@stytch/vanilla-js";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import Index from "./routes/Index";
import StyleGuide from "./routes/StyleGuide";
import Insights from "./routes/Insights";
import Authenticate from "./routes/Authenticate";
import Login from "./routes/Login";
import Navbar from "./components/Navbar";
import Admin from "./routes/Admin";
import AddItem from "./routes/AddItem"; // add a new item to your closet
import NewItem from "./routes/NewItem"; // create a new item to add to backend
import PrivacyPolicy from "./routes/PrivacyPolicy";
import TermsOfService from "./routes/TermsOfService";
import ItemDetail from "./components/ItemDetail";
import Footer from "./components/Footer";
import AuthProvider from "./context/AuthProvider";
import { ItemsProvider } from "./context/ItemsContext";
import { ToastProvider } from "./components/Toast";
import Closet from "./routes/Closet";
import NewOutfit from "./routes/NewOutfit";
import NotFoundPage from "./routes/404";
import ProtectedRoutes from "./routes/ProtectedRoutes";

const stytch = new StytchUIClient(process.env.REACT_APP_STYTCH_PUBLIC_TOKEN);

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure it takes the full viewport height */
`;

const ContentWrapper = styled.main`
  flex-grow: 1; /* Allow the content to expand and push the footer down */
`;

function App() {
  return (
    <StytchProvider stytch={stytch}>
      <AuthProvider>
        <ToastProvider>
          <ItemsProvider>
            <Router>
              <AppContainer>
                <Navbar />
                <ContentWrapper>
                  <Routes>
                    <Route path="/" element={<Index />}>
                      {/* Your main page content */}
                    </Route>

                    <Route path="/privacy" element={<PrivacyPolicy />} />
                    <Route path="/terms" element={<TermsOfService />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/authenticate" element={<Authenticate />} />
                    <Route path="/admin/users/:userkey" element={<Admin />} />

                    <Route path="/item/add" element={<AddItem />} />
                    <Route path="/item/new" element={<NewItem />} />
                    <Route
                      path="/item/:item_purchase_id"
                      element={<ItemDetail />}
                    />

                    <Route element={<ProtectedRoutes />}>
                      <Route path="/styleguide" element={<StyleGuide />} />
                      <Route path="/outfit/new" element={<NewOutfit />} />

                      <Route path="/insights" element={<Insights />} />
                      <Route path="/closet" element={<Closet />} />
                    </Route>

                    <Route path="*" element={<NotFoundPage />} />
                  </Routes>
                </ContentWrapper>
                <Footer />
              </AppContainer>
            </Router>
          </ItemsProvider>
        </ToastProvider>
      </AuthProvider>
    </StytchProvider>
  );
}

export default App;
