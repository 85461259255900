import styled from "styled-components";

export const AddButton = styled.button`
  padding: 10px 20px;
  border: none;
  background-color: #28a745;
  color: white;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: #218838;
  }
`;

export const BackButton = styled.button`
  display: inline-block;
  margin-bottom: 20px;
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: #0056b3;
  }
`;

export const EditButton = styled.button`
  padding: 10px 20px;
  border: none;
  background-color: #ffc107;
  color: white;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: #e0a800;
  }
`;

export const DeleteButton = styled.button`
  padding: 10px 20px;
  border: none;
  background-color: #dc3545;
  color: white;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: #c82333;
  }
`;

export const SaveButton = styled.button`
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #218838;
  }
`;

export const CancelButton = styled.button`
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #6c757d;
  color: white;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #5a6268;
  }
`;
