import React, { useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthProvider";
import { ItemsContext } from "../context/ItemsContext";
import { useToast } from "../components/Toast";
import styled from "styled-components";

const ResultsContainer = styled.div`
  padding: 20px;
`;

const ResultGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const UploadedImage = styled.img`
  width: 300px; /* Increased width for better visibility */
  height: auto;
  margin-right: 20px;

  @media (min-width: 768px) {
    width: 400px; /* Adjusted for larger screens */
  }
`;

const ResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ResultsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Limited to 3 items per row */
  gap: 20px;
  margin-bottom: 20px; /* Added space before the 'None of These Match' button */
`;

const ResultCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column; /* Ensure items are stacked vertically */
  justify-content: space-between; /* Spread title, image, and buttons */
`;

const ResultImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 4px;
`;

const ResultTitle = styled.h4`
  margin: 10px 0;
`;

const FallbackBox = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #f0f0f0;
  color: #888;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
`;

const PublishedDate = styled.p`
  font-size: 14px;
  color: #555;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const ExternalLinkButton = styled.a`
  padding: 5px 10px;
  background-color: blue;
  color: white;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: darkblue;
  }
`;

const SelectButton = styled.button`
  margin-top: 10px;
  padding: 5px 10px;
  background-color: ${({ "data-isselected": isselected }) =>
    isselected ? "green" : "black"};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${({ "data-isselected": isselected }) =>
      isselected ? "darkgreen" : "gray"};
  }
`;

const NoneButton = styled.button`
  padding: 10px;
  background-color: ${({ "data-isselected": isselected }) =>
    isselected ? "red" : "black"};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: ${({ "data-isselected": isselected }) =>
      isselected ? "darkred" : "gray"};
  }
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  background-color: ${({ disabled }) => (disabled ? "gray" : "blue")};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  &:hover {
    background-color: ${({ disabled }) => (disabled ? "gray" : "darkblue")};
  }
`;

const NewItemPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { userkey } = useContext(AuthContext);
  const { reloadItems } = useContext(ItemsContext);

  // Retrieve bingResults from navigation state
  const { results: bingResults } = location.state || {};

  const [selectedItems, setSelectedItems] = useState(
    Array(bingResults.length).fill(null)
  );

  const handleSelect = (groupIndex, resultIndex) => {
    const newSelections = [...selectedItems];
    newSelections[groupIndex] = resultIndex; // Set selected item for the group
    setSelectedItems(newSelections);
  };

  const handleNoneSelect = (groupIndex) => {
    const newSelections = [...selectedItems];
    newSelections[groupIndex] = "none"; // Mark as "none selected"
    setSelectedItems(newSelections);
  };

  const isSubmitDisabled = selectedItems.some(
    (selection) => selection === null
  );

  const handleSubmit = async () => {
    if (isSubmitDisabled) console.log("disabled");
    if (isSubmitDisabled) return;

    // Prepare submission data
    const submission = bingResults.map((resultGroup, groupIndex) => ({
      uploadedImage: resultGroup.original_url,
      selectedResult:
        selectedItems[groupIndex] === "none"
          ? null // Indicate no selection for this group
          : resultGroup.bing_results[selectedItems[groupIndex]],
    }));

    // Filter out groups where no item was selected
    const itemsToSubmit = submission
      .filter((item) => item.selectedResult !== null) // Exclude unselected groups
      .map((item) => ({
        // item_id: item.selectedResult.id, // Assuming `selectedResult` contains `id`
        userkey: userkey,
        name: item.selectedResult.name,
        image_urls: item.selectedResult.image_urls,
        store_url: item.selectedResult.url,
        uploaded_image_url: item.uploadedImage,
      }));

    // Check if there are items to submit
    if (itemsToSubmit.length === 0) {
      console.log("No items selected to submit.");
      navigate("/item/add");
      showToast("No new items added to closet.", "warning");
    }

    try {
      // Make API call with a JSON array payload
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/item_purchases/new`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(itemsToSubmit), // Send the array directly as the body
        }
      );

      if (response.ok) {
        console.log("All item purchases successfully created.");
        const data = await response.json();

        // Reload items using the centralized logic in ItemsContext
        await reloadItems(userkey);

        // Navigate based on response structure
        if (Array.isArray(data)) {
          console.log("Response is an array. Navigating to /closet.");
          navigate("/closet");
        } else if (data && data.purchase_id) {
          console.log(
            `Response is a single object. Navigating to /item/${data.purchase_id}.`
          );
          navigate(`/item/${data.purchase_id}`);
        } else {
          console.warn("Unexpected response format:", data);
          alert("Unexpected response format from the server.");
        }
      } else {
        console.error("Failed to submit batch request.");
        const errorData = await response.json();
        alert(
          `An error occurred while submitting item purchases: ${errorData.message}`
        );
      }
    } catch (error) {
      console.error("Error submitting item purchases:", error);
      alert("An error occurred while submitting item purchases.");
    }
  };

  return (
    <ResultsContainer>
      <h1>Which item best matches the uploaded image?</h1>
      {bingResults.map((group, groupIndex) => (
        <ResultGroup key={groupIndex}>
          <UploadedImage
            src={group.processed_url}
            alt={`Uploaded Image ${groupIndex + 1}`}
          />
          <ResultsWrapper>
            <ResultsGrid>
              {group.bing_results.map((result, resultIndex) => (
                <ResultCard key={resultIndex}>
                  <ResultTitle>{result.name}</ResultTitle>
                  {result.image_urls ? (
                    <ResultImage src={result.image_urls[0]} alt={result.name} />
                  ) : (
                    <FallbackBox>Thumbnail Unavailable</FallbackBox>
                  )}
                  <PublishedDate>
                    {result.snippet}
                    {/* {new Date(result.date_published).toLocaleDateString()} */}
                  </PublishedDate>
                  <ButtonContainer>
                    <ExternalLinkButton
                      href={result.host_page_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Visit Site
                    </ExternalLinkButton>
                    <SelectButton
                      onClick={() => handleSelect(groupIndex, resultIndex)}
                      data-isselected={
                        selectedItems[groupIndex] === resultIndex
                      }
                    >
                      {selectedItems[groupIndex] === resultIndex
                        ? "Selected"
                        : "Select"}
                    </SelectButton>
                  </ButtonContainer>
                </ResultCard>
              ))}
            </ResultsGrid>
            <NoneButton
              onClick={() => handleNoneSelect(groupIndex)}
              data-isselected={selectedItems[groupIndex] === "none"}
            >
              None of These Match
            </NoneButton>
          </ResultsWrapper>
        </ResultGroup>
      ))}
      <SubmitButton onClick={handleSubmit} disabled={isSubmitDisabled}>
        Submit Selections
      </SubmitButton>
    </ResultsContainer>
  );
};

export default NewItemPage;
