import React, { createContext, useState, useContext, useEffect } from "react";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userkey, setUserkey] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [authLoaded, setAuthLoaded] = useState(false);

  const updateAuthState = () => {
    const loggedIn = localStorage.getItem("isLoggedIn") === "true";
    const userkey = localStorage.getItem("userkey");
    const idToken = localStorage.getItem("id_token");

    setIsLoggedIn(loggedIn);
    setUserkey(userkey);
    setIdToken(idToken);
    setAuthLoaded(true);
  };

  useEffect(() => {
    // Initialize state from localStorage on mount
    updateAuthState();

    // Listen for changes to localStorage across different tabs/windows
    const handleStorageChange = () => {
      updateAuthState();
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const signOut = () => {
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("id_token");
    localStorage.removeItem("userkey");
    updateAuthState();
  };

  if (!authLoaded) {
    return <div>Loading...</div>; // You can replace this with a splash screen or spinner
  }

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, setIsLoggedIn, userkey, idToken, signOut }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
