import React from 'react';
import styled from 'styled-components';

const ItemImage = styled.img`
  width: 100%;
`;

const ItemName = styled.h3`
  margin: 10px 0;
`;

const ItemDescription = styled.p`
  font-size: 14px;
`;

const ItemCardContainer = styled.div`
  position: relative;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: ${({ $isAdded }) => ($isAdded ? '#d3d3d3' : '#fff')}; /* Gray out if added */
  text-align: center;
  overflow: hidden;
  pointer-events: ${({ $isAdded }) => ($isAdded ? 'none' : 'auto')}; /* Disable interaction if added */
  opacity: ${({ $isAdded }) => ($isAdded ? 0.6 : 1)}; /* Dim the item if added */

  &:hover .overlay {
    opacity: ${({ $isAdded }) => ($isAdded ? 1 : 0.9)}; /* Show fully if added, else partially on hover */
    transform: translateY(0);
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ $isAdded }) => ($isAdded ? 1 : 0)}; /* Always visible if added */
  transform: translateY(0);
  transition: opacity 0.3s ease, transform 0.3s ease;
  cursor: ${({ $isAdded }) => ($isAdded ? 'default' : 'pointer')};
  font-size: 18px;
`;

const SearchItemCard = ({ item, isAdded, onAddToCloset }) => {
  return (
    <ItemCardContainer $isAdded={isAdded}>
      <ItemImage src={item.image_urls?.[0]} alt={item.name} />
      <ItemName>{item.name}</ItemName>
      <ItemDescription>{item.description}</ItemDescription>
      <Overlay
        className="overlay"
        $isAdded={isAdded}
        onClick={() => !isAdded && onAddToCloset(item)} // Only trigger add to closet if not added
      >
        {isAdded ? 'Added' : 'Add to Closet'}
      </Overlay>
    </ItemCardContainer>
  );
};

export default SearchItemCard;
