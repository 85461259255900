import React, { useState } from "react";
import styled from "styled-components";
import ItemCard from "../components/ItemCard";
import { useNavigate } from "react-router-dom";
import { AddButton } from "./Buttons";

const ClosetWrapper = styled.div`
  display: flex;
  padding: 20px;
`;

const SidebarContainer = styled.div`
  width: 180px;
  background-color: #ffffff;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  border-radius: 10px;
  font-family: "Arial", sans-serif;
`;

const SearchInput = styled.input`
  width: 50%;
  box-sizing: border-box;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  outline: none;
  font-size: 12px;
  color: #333;

  &:focus {
    border-color: #0056b3;
    box-shadow: 0 0 5px rgba(0, 86, 179, 0.2);
  }
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

const CategoryButton = styled.button`
  width: 100%;
  padding: 10px 10px;
  border: none;
  background-color: ${({ selected }) => (selected ? "#000000" : "white")};
  color: ${({ selected }) => (selected ? "white" : "black")};
  cursor: pointer;
  text-align: left;
  font-size: 14px;
  border-radius: 8px;

  &:hover {
    background-color: grey;
    color: white;
  }

  &:active {
    background-color: #0056b3;
  }
`;

const MainContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
`;

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px 20px;
  gap: 10px;
  width: 100%;
`;

const SortDropdown = styled.select`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 12px;
  cursor: pointer;

  &:focus {
    border-color: #0056b3;
    box-shadow: 0 0 5px rgba(0, 86, 179, 0.2);
  }
`;

const ItemGrid = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  padding: 10px;

  @media (min-width: 900px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 600px) and (max-width: 899px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 599px) {
    grid-template-columns: 1fr;
  }

  > div {
    max-height: 400px;
    overflow: hidden;
  }
`;

const categories = ["all", "top", "bottom", "dress", "shoes", "accessories"];

const Closet = ({ items }) => {
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOption, setSortOption] = useState("default");
  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredItems = items.filter((item) => {
    const matchesCategory =
      selectedCategory === "all" ||
      item.item_type?.toLowerCase() === selectedCategory;

    const matchesSearchQuery =
      item.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.brand?.toLowerCase().includes(searchQuery.toLowerCase());

    return matchesCategory && matchesSearchQuery;
  });

  // Sorting logic
  const sortedItems = [...filteredItems].sort((a, b) => {
    if (sortOption === "color") {
      return (a.color || "").localeCompare(b.color || "");
    } else if (sortOption === "brand") {
      return (a.brand || "").localeCompare(b.brand || "");
    } else if (sortOption === "item_number") {
      return (a.purchase_id || 0) - (b.purchase_id || 0); // Oldest first
    } else if (sortOption === "newest_first") {
      return (b.purchase_id || 0) - (a.purchase_id || 0); // Newest first
    }
    return 0;
  });

  return (
    <ClosetWrapper>
      <SidebarContainer>
        <FilterContainer>
          {categories.map((category) => (
            <CategoryButton
              key={category}
              onClick={() => handleCategoryClick(category)}
              selected={selectedCategory === category}
            >
              {category}
            </CategoryButton>
          ))}
        </FilterContainer>
      </SidebarContainer>

      <MainContentContainer>
        <h1>My Wardrobe</h1>
        <ControlsContainer>
          <SearchInput
            type="text"
            placeholder="Search your closet..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <SortDropdown
            value={sortOption}
            onChange={(e) => setSortOption(e.target.value)}
          >
            <option value="default">Sort By</option>
            <option value="color">Color A-Z</option>
            <option value="brand">Brand A-Z</option>
            <option value="item_number">Oldest Entry First</option>
            <option value="newest_first">Newest Entry First</option>
          </SortDropdown>
          <AddButton onClick={() => navigate("/item/add")}>Add Items</AddButton>
        </ControlsContainer>
        <ItemGrid>
          {sortedItems.map((item) => (
            <ItemCard key={item.purchase_id} item={item} />
          ))}
        </ItemGrid>
      </MainContentContainer>
    </ClosetWrapper>
  );
};

export default Closet;
